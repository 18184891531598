/* eslint-disable no-useless-escape */
import fetching from './fetching';
import importPolyfill from './polyfill';

import { trackBanner } from './utils/tracking';
import { createUUID } from './utils/random';
import { getCookie } from './utils/cookies';
import { setDefaultAutoRefresh } from './utils/auto-refresh';
import { enabledGetFromMemory } from './utils/memory';
import { enabledReload } from './utils/reload';

const stringify = (value: Array<TrackUrl>) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return JSON.stringify([]);
  }
};

const setObserver = (lazy = 10, template: HTMLElement) => new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.appendChild(template);
      observer.disconnect();
    }
  });
}, {
  root: null,
  rootMargin: `${lazy}%`,
});

const hasUID = (banner: Element): boolean => banner.hasAttribute('data-uid');

const getUID = (banner: Element): string => banner.getAttribute('data-uid') || '';

const setUID = (banner: Element): void => banner.setAttribute('data-uid', createUUID());

const getBannerObserver = (banner: Element) => window['adn-scripts'][getUID(banner)] as unknown as IntersectionObserver;

const setBannerObserver = (banner: Element, observer: (IntersectionObserver | null)) => {
  window['adn-scripts'][getUID(banner)] = observer;
};

const disconnectBannerObserver = (banner: Element) => {
  if (getBannerObserver(banner)) {
    getBannerObserver(banner).disconnect();
    setBannerObserver(banner, null);
  }
};

const cleanBanner = (banner: Element) => {
  disconnectBannerObserver(banner);
  while (banner.firstChild) {
    banner.removeChild(banner.firstChild);
  }
};

const getFastLoadingBanner = (banner: Element): BannerResponse | null => {
  const dataContent = banner.getAttribute('data-content') || '{}';

  try {
    const { fast_loading, ...rest } = JSON.parse(dataContent);
    if (fast_loading) {
      banner.setAttribute('data-content', JSON.stringify(rest));
      return fast_loading as BannerResponse;
    }
    return null;
  } catch (error) {
    trackBanner(banner, 'Invalid JSON data-content');
    return null;
  }
};

const storeBannerDataInMemory = (banner: Element, items: Banner[]) => {
  const dataContent = JSON.parse(banner.getAttribute('data-content') || '{}');
  window.adnTemplateCache = JSON.stringify({ ...dataContent,
    memory: {
      items,
    },
  });
};

const getFromMemory = (banner: Element): Promise<BannerFetched> => new Promise((resolve, reject) => {
  try {
    const dataContent = window.adnTemplateCache as string || '{}';
    const { memory } = JSON.parse(dataContent);
    const fastLoadingBanner = getFastLoadingBanner(banner);

    if (memory) {
      const { items: [item], items: itemsArray } = memory;
      resolve({ item, banner, itemsArray });
      return;
    }

    if (fastLoadingBanner) {
      const { items: [item], items: itemsArray } = fastLoadingBanner;
      storeBannerDataInMemory(banner, itemsArray);
      resolve({ item, banner, itemsArray });
      return;
    }

    reject();
  } catch (error) {
    trackBanner(banner, 'Invalid JSON data-content');
    reject();
  }
});

const getParams = (banner: Element): URLSearchParams => {
  const excludeAttributes = ['component', 'content', 'uid'];
  let acc = {} as Record<string, string>;
  let i = 0;
  let flag = true;

  while (i < banner.attributes.length && flag) {
    const attrKey = banner.attributes[i].name.replace('data-', '') ?? '';

    if (!excludeAttributes.includes(attrKey)) {
      acc[`${attrKey}`] = banner.attributes[i].value;
    }

    if (attrKey === 'content') {
      acc = JSON.parse(banner.attributes[i].value).queries;
      flag = false;
    }

    i += 1;
  }
  return new URLSearchParams(acc);
};

const fetchBanner = (banner: Element): Promise<BannerFetched> => new Promise((resolve, reject) => {
  cleanBanner(banner);
  if (!hasUID(banner)) {
    setUID(banner);
  }
  const fastLoadingBanner = getFastLoadingBanner(banner);
  const params = getParams(banner);
  if (fastLoadingBanner) {
    const { items: [item], items: itemsArray } = fastLoadingBanner;
    if (enabledReload(params) || enabledGetFromMemory(params)) {
      storeBannerDataInMemory(banner, itemsArray);
    }
    resolve({ item, banner, itemsArray });
  } else {
    params.set('unique_id', createUUID());
    fetching(params)
      .then((result: BannerResponse) => {
        if (!result) {
          throw new Error('no data');
        }
        if (result?.items.length) {
          const [item] = result.items;
          if (enabledReload(params) || enabledGetFromMemory(params)) {
            storeBannerDataInMemory(banner, result.items);
          }
          banner?.setAttribute('style', 'display:block;');
          resolve({ item, banner });
        } else {
          trackBanner(banner, `Empty response, with params ${params.toString()}`);
          reject();
        }
      })
      .catch((error: Error) => {
        if (enabledGetFromMemory(params)) {
          getFromMemory(banner)
            .then((value: BannerFetched) => {
              resolve(value);
            })
            .catch(() => {
              trackBanner(banner, 'error gettting from memory');
              reject();
            });
          return;
        }
        trackBanner(banner, `fail fetching: ${error.message}, with params ${params.toString()}`);
        reject();
      });
  }
});

const isLazy = (percentage: number): boolean => (percentage > -1)
  && ('IntersectionObserver' in window)
  && ('IntersectionObserverEntry' in window)
  && ('intersectionRatio' in window.IntersectionObserverEntry.prototype)
  && ('isIntersecting' in window.IntersectionObserverEntry.prototype);

const setAutoRefresh = (banner: Element, adnScript: () => void) => {
  setDefaultAutoRefresh(banner, getParams(banner));
  const autoRefresh = banner.getAttribute('data-auto_refresh') ?? '';

  if (autoRefresh === 'initial') {
    banner.setAttribute('data-auto_refresh', 'ready');

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && window.adn === 'finished') {
        if (banner?.hasAttribute('style')) {
          banner?.removeAttribute('style');
        }

        adnScript();
      }
    });
  }
};

window['adn-scripts'] = {};

const overrideTemplates = (templateUrl: string) => {
  let newTemplateUrl = templateUrl;
  const templateVersion = getCookie('adn-template-version');
  if (templateVersion) {
    const regex = /(\d+\.\d+\.\d+)(?=.js)/;
    newTemplateUrl = newTemplateUrl.replace(regex, `${templateVersion}`);
  }

  const templateNames = getCookie('adn-template-names');
  if (templateNames) {
    const names = templateNames.split(',');
    names.forEach((name) => {
      const [key, newName] = name.split(':');
      const regex = new RegExp(`\/adn-frontend-library\/${key}\..*js`);
      newTemplateUrl = newTemplateUrl.replace(regex, `/adn-frontend-library/${newName}.js`);
    });
  }
  return newTemplateUrl;
};

const adnScript = () => {
  window.adn = 'loaded';
  const banners = document.querySelectorAll('[data-component="adn"]');
  if (banners.length > 0) {
    Promise.allSettled([...banners].map(fetchBanner)).then((promises: Array<PromiseSettledResult<BannerFetched>>) => {
      const onReady = ({
        item: {
          content: { actions: { content_link: { link } }, texts, images, colors },
          event_tracking: { print, click, view },
          template,
          lazy_loading_print_percentage,
          is_off_meli,
        }, banner,
        itemsArray,
      }: BannerFetched) => {
        cleanBanner(banner);

        const templateTag = getCookie('adn-template-tag') || template.tag;
        const element = document.createElement(`${templateTag}`);

        if (Array.isArray(itemsArray) && itemsArray.length > 1) {
          element.setAttribute('items', JSON.stringify(itemsArray));
        } else {
          Object.keys(texts).forEach(
            (key: string) => element.setAttribute(key, texts[key as keyof Texts]),
          );
          Object.keys(images).forEach(
            (key: string) => element.setAttribute(key, images[key as keyof Images]),
          );
          Object.keys(colors).forEach(
            (key: string) => element.setAttribute(key, colors[key as keyof Colors]),
          );
          element.setAttribute('destination_url', link);
          element.setAttribute('print_url', stringify(print));
          element.setAttribute('click_url', stringify(click));
          if (view) {
            element.setAttribute('view_url', stringify(view));
          }
          if (is_off_meli) {
            element.setAttribute('target', '_blank');
          }
        }
        if (isLazy(lazy_loading_print_percentage)) {
          const lazyBanner = setObserver(lazy_loading_print_percentage, element);
          lazyBanner.observe(banner);
          setBannerObserver(banner, lazyBanner);
        } else {
          banner.appendChild(element);
        }

        trackBanner(banner);
      };
      promises.forEach((promise) => {
        if (promise.status === 'fulfilled') {
          const {
            item: { template },
            banner,
          } = promise.value;

          if (!window['adn-scripts'][template.url]) {
            const templateUrl = overrideTemplates(template.url);
            window['adn-scripts'][template.url] = templateUrl;
            const script = document.createElement('script');
            script.src = templateUrl;
            script.defer = true;
            script.onload = () => onReady(promise.value);
            document.head.appendChild(script);
          } else {
            onReady(promise.value);
          }
          trackBanner(banner);
        }
      });
    }).catch(() => {
      banners.forEach((banner: Element) => trackBanner(banner, 'error adding banners'));
    }).finally(() => {
      window.adn = 'finished';
      banners.forEach((banner: Element) => setAutoRefresh(banner, adnScript));
    });
  } else {
    trackBanner(undefined, 'banners not found');
  }
};

const reloadData = () => {
  window.adn = 'loaded';
  const banners = document.querySelectorAll('[data-component="adn"]');

  if (banners.length > 0) {
    Promise.allSettled([...banners].map(getFromMemory)).then((promises: Array<PromiseSettledResult<BannerFetched | null >>) => {
      const onReady = ({
        item: {
          content: { actions: { content_link: { link } }, texts, images, colors },
          event_tracking: { print, click, view },
          template,
          is_off_meli,
        }, banner,
        itemsArray,
      }: BannerFetched) => {
        cleanBanner(banner);

        const templateTag = getCookie('adn-template-tag') || template.tag;
        const element = document.createElement(`${templateTag}`);

        if (Array.isArray(itemsArray) && itemsArray.length > 1) {
          element.setAttribute('items', JSON.stringify(itemsArray));
        } else {
          Object.keys(texts).forEach(
            (key: string) => element.setAttribute(key, texts[key as keyof Texts]),
          );
          Object.keys(images).forEach(
            (key: string) => element.setAttribute(key, images[key as keyof Images]),
          );
          Object.keys(colors).forEach(
            (key: string) => element.setAttribute(key, colors[key as keyof Colors]),
          );
          element.setAttribute('destination_url', link);
          element.setAttribute('print_url', stringify(print));
          element.setAttribute('click_url', stringify(click));
          if (view) {
            element.setAttribute('view_url', stringify(view));
          }
          if (is_off_meli) {
            element.setAttribute('target', '_blank');
          }
        }
        banner.appendChild(element);
        trackBanner(banner);
      };
      promises.forEach((promise) => {
        if (promise.status === 'fulfilled' && promise.value) {
          const {
            item: { template },
            banner,
          } = promise.value;

          if (!window['adn-scripts'][template.url]) {
            const templateUrl = overrideTemplates(template.url);
            window['adn-scripts'][template.url] = templateUrl;
            const script = document.createElement('script');
            script.src = templateUrl;
            script.defer = true;
            script.onload = () => onReady(promise.value!);
            document.head.appendChild(script);
          } else {
            onReady(promise.value);
          }
          trackBanner(banner);
        }
      });
    }).catch(() => {
      banners.forEach((banner: Element) => trackBanner(banner, 'error adding banners'));
    }).finally(() => {
      window.adn = 'finished';
      banners.forEach((banner: Element) => setAutoRefresh(banner, adnScript));
    });
  } else {
    trackBanner(undefined, 'banners not found');
  }
};

document.body.addEventListener('adn-reload', () => {
  reloadData();
});

const init = () => {
  importPolyfill(adnScript);
};

export default init();
